<template>
  <div class="container-fluid">
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <div class="callback m-auto">
          {{ $t('message.redirecting_to_your_space') }}
          <div class="m-auto mt-4 dot-flashing"></div>
        </div>
        <div v-if="errorMessage" class="m-auto mdl-color-text--red-500 text-center">
          <br>
          <span>{{ $t(errorMessage) }}</span>
          <br>
          <br>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LoginLayout from '../../layouts/LoginLayout'
import config from '../../config'
import Vue from 'vue'

export default {
  name: 'LoginBudgetCodeCallback',
  data: () => ({
    errorMessage: null
  }),
  created () {
    this.$emit('update:layout', LoginLayout)

    document.title = config.title + ' - ' + this.$t('message.login')

    if (this.$route.query.ticket) {
      this.casLogin(this.$route.query.ticket)
    }
  },
  methods: {
    casLogin (ticket) {
      const service = window.location.protocol + '//' + window.location.host + window.location.pathname

      Vue.prototype.$http
        .get(config.cffCasUrl + '?ticket=' + ticket + '&service=' + service)
        .then(response => {
          if (response.status === 200) {
            const data = response.data

            if (data.error) {
              if (data.error === 101) {
                this.errorMessage = 'message.connection_error'
              }

              if (data.error === 102) {
                this.errorMessage = 'message.no_access_granted'
              }

              if (data.error === 103) {
                this.errorMessage = 'message.access_expired'
              }

              setTimeout(() => {
                this.$router.push({ name: 'LoginBudget', query: { error: data.error } })
              }, 5000)
            } else if (data) {
              this.$store.dispatch('loginCas', { data })
            }
          }
        })
        .catch(() => {
          this.errorMessage = 'message.connection_error'

          setTimeout(() => {
            this.$router.push({ name: 'LoginBudget', query: { error: '101' } })
          }, 2000)
        })
    }
  }
}
</script>
